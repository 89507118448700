'use client'

import { useQuery } from '@tanstack/react-query'
import { CustomLink } from '@vendure/components/shared/client'
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@vendure/components/ui'
import Link from 'next/link'
import { HiUser } from 'react-icons/hi'
import { SignOutButton } from './sign-out/SignOutButton'

export function AccountLink() {
  const { data } = useQuery({
    queryKey: ['activeCustomer'],
    queryFn: async () => {
      const res = await fetch('/api/customer')
      return await res.json()
    },
  })

  const isLoggedIn = data?.activeCustomer != null

  return !isLoggedIn ? (
    <Button
      asChild
      variant="ghost"
      className="flex items-center gap-2"
    >
      <Link href="/sign-in">
        <HiUser />
        Sign in
      </Link>
    </Button>
  ) : (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost">
          <span className="flex items-center gap-2">
            <HiUser />
            {data.activeCustomer?.firstName} {data.activeCustomer?.lastName}
          </span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="mt-1">
        <DropdownMenuItem
          asChild
          className="cursor-pointer"
        >
          <CustomLink href="/account">Account</CustomLink>
        </DropdownMenuItem>
        <DropdownMenuItem
          asChild
          className="cursor-pointer"
        >
          <CustomLink href="/account/settings">Settings</CustomLink>
        </DropdownMenuItem>
        <DropdownMenuItem className="cursor-pointer">
          <SignOutButton />
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
