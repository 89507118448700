'use client'

import { useRouter } from 'next/navigation'
import { logout } from './actions'

export function SignOutButton() {
  const router = useRouter()
  return (
    <form>
      <button
        formAction={async () => {
          await logout()
          router.push('/sign-in')
        }}
      >
        Sign Out
      </button>
    </form>
  )
}
